/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bandaid: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14.121 1.879a3 3 0 00-4.242 0L8.733 3.026l4.261 4.26 1.127-1.165a3 3 0 000-4.242M12.293 8L8.027 3.734 3.738 8.031 8 12.293zm-5.006 4.994L3.03 8.737 1.879 9.88a3 3 0 004.241 4.24l.006-.006 1.16-1.121zM2.679 7.676l6.492-6.504a4 4 0 015.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 01-5.653-5.66l.001-.002 1.505-1.492z"/><path pid="1" d="M5.56 7.646a.5.5 0 11-.706.708.5.5 0 01.707-.708zm1.415-1.414a.5.5 0 11-.707.707.5.5 0 01.707-.707M8.39 4.818a.5.5 0 11-.708.707.5.5 0 01.707-.707zm0 5.657a.5.5 0 11-.708.707.5.5 0 01.707-.707zM9.803 9.06a.5.5 0 11-.707.708.5.5 0 01.707-.707zm1.414-1.414a.5.5 0 11-.706.708.5.5 0 01.707-.708zM6.975 9.06a.5.5 0 11-.707.708.5.5 0 01.707-.707zM8.39 7.646a.5.5 0 11-.708.708.5.5 0 01.707-.708zm1.413-1.414a.5.5 0 11-.707.707.5.5 0 01.707-.707"/>',
    },
});
